import React, { useState, useEffect } from 'react';
// import { GlobalHotKeys } from 'react-hotkeys';

import { Marker, Tooltip, Popup } from 'react-leaflet';
import L from 'leaflet';
// import styles from '../styles/Home.module.css';
import settingsStore from '../Store/settingsStore';
import { observer } from 'mobx-react-lite';

const CHAT_POLLING_INTERVAL = 600000;

function WeatherStations() {
  const [lastChatDate, setLastChatDate] = useState(new Date().toDateString());
  const [wxStation, setWxStation] = useState([]);
  useEffect(() => {
    fetch(`https://crc.team/live/api/getWeather.php`)
      .then((res) => {
        if (res.status === 200) return res.json();
        else return [];
      })
      .then(
        (list) => {
          if (list.length) {
            setWxStation(list);
          }
          setTimeout(() => {
            setLastChatDate(new Date().toISOString());
          }, CHAT_POLLING_INTERVAL);
        },
        (error) => {
          if (error) {
            console.log('Chat loading error');
            console.log(error);
          }
        }
      );
  }, [lastChatDate]);

  const wxStationsMarkers = [];
  if (settingsStore.showWeather) {
    wxStation.forEach(function (receiver) {
      const antennaIcon = new L.divIcon({
        html: `
          <div>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                viewBox="-30 0 90 60" xml:space="preserve">
            <ellipse style="fill:#BDD7E5;" cx="19" cy="20" rx="4" ry="12"/>
            <path style="fill:#ABCADD;" d="M19,33c-3.282,0-5-6.54-5-13s1.718-13,5-13s5,6.54,5,13S22.282,33,19,33z M19,9
              c-1.019,0-3,3.883-3,11s1.981,11,3,11s3-3.883,3-11S20.019,9,19,9z"/>
            <path style="fill:#E4EFF7;" d="M56,13L19,8c2.209,0,4,5.373,4,12c0,6.627-1.791,12-4,12l37-5c1.657,0,3-2.029,3-7
              C59,15.029,57.657,13,56,13z"/>
            <path style="fill:#ABCADD;" d="M19,33l-0.134-1.991l0.285-0.038C20.333,30.553,22,26.479,22,20S20.333,9.447,19.151,9.029
              l-0.285-0.038L19,7c0.186,0,0.372,0.022,0.556,0.066l36.518,4.935C57.574,12.035,60,13.152,60,20s-2.426,7.965-3.927,7.999
              l-36.518,4.935C19.372,32.978,19.186,33,19,33z M22.034,9.419C23.372,11.981,24,16.175,24,20s-0.628,8.019-1.966,10.581L56,26
              c0.923,0,2-1.571,2-6s-1.077-6-2-6l-0.134-0.009L22.034,9.419z"/>
            <path style="fill:#ABCADD;" d="M18.293,32.707l-14-14c-0.208-0.208-0.313-0.496-0.29-0.789c0.024-0.293,0.177-0.561,0.416-0.731
              l14-10l1.162,1.627L6.542,18.128l13.165,13.165L18.293,32.707z"/>
            <path style="fill:#E64C3C;" d="M51.345,13.38l-2.124-0.287C49.718,15.083,50,17.492,50,20c0,2.511-0.283,4.923-0.781,6.915
              l2.124-0.286C51.764,24.642,52,22.353,52,20C52,17.65,51.765,15.364,51.345,13.38z"/>
            <path style="fill:#E64C3C;" d="M45.157,12.544l-2.154-0.291C43.637,14.356,44,17.131,44,20c0,2.871-0.364,5.649-0.999,7.753
              l2.155-0.291C45.701,25.309,46,22.726,46,20C46,17.277,45.702,14.696,45.157,12.544z"/>
            <path style="fill:#E64C3C;" d="M38.923,11.701l-2.209-0.298C37.521,13.554,38,16.704,38,20c0,3.299-0.48,6.45-1.288,8.601
              l2.209-0.298C39.611,26.019,40,23.101,40,20C40,16.902,39.612,13.985,38.923,11.701z"/>
            <line style="fill:none;stroke:#7F8C8D;stroke-width:2;stroke-miterlimit:10;" x1="5" y1="49" x2="5" y2="9"/>
            <circle style="fill:#BDC3C7;stroke:#95A5A5;stroke-width:2;stroke-miterlimit:10;" cx="5" cy="5" r="4"/>
            <rect x="3" y="49" style="fill:#BDC3C7;stroke:#7F8C8D;stroke-width:2;stroke-miterlimit:10;" width="4" height="6"/>
            <rect x="1" y="55" style="fill:#BDC3C7;stroke:#7F8C8D;stroke-width:2;stroke-miterlimit:10;" width="8" height="4"/>
            </svg>
          </div>
        `,
        iconSize: [60, 60],
        className: 'glider-icon-red'
      });
      var date = new Date(receiver.time);
      var localTime = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

      wxStationsMarkers.push(
        <Marker key={receiver.aprsID} position={[receiver.lat, receiver.lon]} icon={antennaIcon}>
          <Tooltip>{receiver.aprsID}</Tooltip>
          <Popup>
            <b>{receiver.aprsID} </b>({localTime.toLocaleString()})
            <hr style={{ margin: '15px 0' }} />
            <table>
              <tbody valign="top">
                <tr>
                  <td>
                    <b>Погода:</b>
                  </td>
                  <td>{receiver.weather}</td>
                </tr>
                {receiver.cloudiness > 0 && (
                  <tr>
                    <td>
                      <b>Облачность:</b>
                    </td>
                    <td>{receiver.cloudiness} %</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Ветер:</b>
                  </td>
                  <td>
                    {receiver.wind_direction}°{' '}
                    {settingsStore.windSpeed == 'ms'
                      ? receiver.wind_speed + ' м/с'
                      : (receiver.wind_speed * 3.6).toFixed(1) + ' км/ч'}
                    {receiver.wind_speed_peak > 0 &&
                      receiver.wind_speed < receiver.wind_speed_peak &&
                      ' (' +
                        (settingsStore.windSpeed == 'ms'
                          ? receiver.wind_speed_peak + ' м/с'
                          : (receiver.wind_speed_peak * 3.6).toFixed(1) + ' км/ч') +
                        ')'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Температура:</b>
                  </td>
                  <td>{receiver.temperature} °C</td>
                </tr>
                {receiver.humidity > 0 && (
                  <tr>
                    <td>
                      <b>Влажность:</b>
                    </td>
                    <td>{receiver.humidity} %</td>
                  </tr>
                )}
                {receiver.rainfall_1h > 0 && (
                  <tr>
                    <td>
                      <b>Осадки:</b>
                    </td>
                    <td>{receiver.rainfall_1h} мм</td>
                  </tr>
                )}
                {receiver.pressure > 0 && (
                  <tr>
                    <td>
                      <b>Давление:</b>
                    </td>
                    <td>{receiver.pressure} гПа</td>
                  </tr>
                )}
                {/* {receiver.data != '' && (
                  <tr>
                    <td>
                      <b>Данные:</b>
                    </td>
                    <td>{receiver.data}</td>
                  </tr>
                )} */}
              </tbody>
            </table>
          </Popup>
        </Marker>
      );
    });
  }

  return wxStationsMarkers;
}

export default observer(WeatherStations);

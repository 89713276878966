import { makeAutoObservable } from 'mobx';

class trackingStore {
  tails = {};
  activePilotData = {};
  activePilot = '';
  constructor() {
    makeAutoObservable(this);
  }
  setActivePilotID(id) {
    this.activePilot = id;
    if (id == '') this.activePilotData = {};
    //console.log('aprsid=' + this.activePilot);
  }
  setActivePilotData(data) {
    this.activePilotData = data;
    //console.log(this.activePilotData.noSignalTime);
  }
  deleteTail(key) {
    delete this.tails[key];
  }
  deleteAllTail() {
    for (var key in this.tails) {
      delete this.tails[key];
    }
  }
}

export default new trackingStore();

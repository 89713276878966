import 'leaflet-velocity';
import L from 'leaflet';
import React from 'react';
import { useLeafletContext } from '@react-leaflet/core';

const WindyLayer = ({ displayValues, displayOptions, data, minVelocity, maxVlocity, opacity }) => {
  const context = useLeafletContext();
  //console.log(data);
  if (data == null || data.lenght == 0) return null;
  //console.log('WindyLayer');
  React.useEffect(() => {
    const velocityLayer = L.velocityLayer({
      displayValues: displayValues,
      displayOptions: displayOptions,
      data: data,
      minVelocity: minVelocity,
      maxVelocity: maxVlocity,
      opacity: opacity
    });
    const container = context.layerContainer || context.map;

    container.addLayer(velocityLayer);

    return () => {
      container.removeLayer(velocityLayer);
    };
  });

  return null;
};

export default WindyLayer;

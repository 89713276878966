import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Badge, ToggleButton, Modal, Tabs, Tab } from 'react-bootstrap';
import { HotKeys } from 'react-hotkeys';
import { MdOutlineSettings } from 'react-icons/md';

import settingsStore from '../Store/settingsStore';

import styles from '../styles/Home.module.css';

const keyMap = {
  SHOW_ALL_GLIDERS: ['shift+g', 'shift+п'],
  SHOW_ALL_GLIDER_TAILS: ['shift+a', 'shift+ф'],
  SHOW_USER_AAT_PATH: ['shift+w', 'shift+ц'],
  SHOW_USER_PROJECTION: ['shift+p', 'shift+з']
};

const handlers = {
  SHOW_ALL_GLIDERS: settingsStore.showAllGliders,
  SHOW_ALL_GLIDER_TAILS: settingsStore.showAllTails,
  SHOW_USER_AAT_PATH: settingsStore.showAatTracks,
  SHOW_USER_PROJECTION: settingsStore.showProjections
};

function SettingsDialog({ currentTask, trackingType }) {
  const toggleShow = () => setShow((s) => !s);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const bagesOpacity = show == true ? 1 : 0;
  const mediaQuery = window.matchMedia('(max-width: 575.98px)');
  if (currentTask == null) return;

  let pilotItems = [];
  let otherItems = [];
  Object.entries(settingsStore.userSettings)
    .sort((a, b) => {
      var textA = a[1].cn.toUpperCase();
      var textB = b[1].cn.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .forEach(([userID]) => {
      settingsStore.userSettings[userID].needScoring == 1 &&
        pilotItems.push(
          <tr key={userID} valign="top">
            <td
              style={{
                backgroundColor: settingsStore.userSettings[userID].userColor
              }}></td>
            <td>{settingsStore.userSettings[userID].cn}</td>
            <td>{settingsStore.userSettings[userID].userName}</td>
            <td>
              <input
                type="checkbox"
                checked={settingsStore.userSettings[userID].showGlider}
                onChange={() => {
                  settingsStore.changeGliderVisibility(userID);
                  setChecked(new Date().toISOString());
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={settingsStore.userSettings[userID].showTail}
                disabled={!settingsStore.userSettings[userID].showGlider}
                onChange={() => {
                  settingsStore.changeTailVisibility(userID);
                  setChecked(new Date().toISOString());
                }}
              />
            </td>
            {currentTask.raceType != 'FF' && (
              <td>
                <input
                  type="checkbox"
                  checked={settingsStore.userSettings[userID].showAatResult}
                  disabled={!settingsStore.userSettings[userID].showGlider}
                  onChange={() => {
                    settingsStore.changeAATResVisibility(userID);
                    setChecked(new Date().toISOString());
                  }}
                />
              </td>
            )}
            {currentTask.raceType != 'FF' && (
              <td>
                <input
                  type="checkbox"
                  checked={settingsStore.userSettings[userID].showProjection}
                  disabled={!settingsStore.userSettings[userID].showGlider}
                  onChange={() => {
                    settingsStore.changeProjectionsVisibility(userID);
                    setChecked(new Date().toISOString());
                  }}
                />
              </td>
            )}
          </tr>
        );
      settingsStore.userSettings[userID].needScoring == 0 &&
        otherItems.push(
          <tr key={userID}>
            <td
              style={{
                backgroundColor: settingsStore.userSettings[userID].userColor
              }}></td>
            <td>{settingsStore.userSettings[userID].cn}</td>
            <td>{settingsStore.userSettings[userID].userName}</td>
            <td>
              <input
                type="checkbox"
                checked={settingsStore.userSettings[userID].showGlider}
                onChange={() => {
                  settingsStore.changeGliderVisibility(userID);
                  setChecked(new Date().toISOString());
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={settingsStore.userSettings[userID].showTail}
                disabled={!settingsStore.userSettings[userID].showGlider}
                onChange={() => {
                  settingsStore.changeTailVisibility(userID);
                  setChecked(new Date().toISOString());
                }}
              />
            </td>
            <td></td>
            <td></td>
          </tr>
        );
    });
  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div
        style={{
          bottom: `27px`,
          left: `10px`,
          position: `fixed`,
          zIndex: `999`
        }}>
        <ToggleButton
          variant="secondary"
          type="checkbox"
          checked={show}
          onClick={toggleShow}
          style={{ paddingLeft: '10px', width: '45px', height: '45px' }}>
          <MdOutlineSettings style={{ fontSize: 'x-large' }} />
        </ToggleButton>
        <Badge
          pill
          bg="success"
          style={{
            top: `4px`,
            left: `35px`,
            position: `absolute`,
            opacity: bagesOpacity
          }}>
          <strong className={styles.buttonBadgeText}>{Object.keys(settingsStore.userSettings).length}</strong>
        </Badge>
      </div>
      <Modal show={show} onHide={() => setShow(false)} scrollable={true} size="lx">
        <Modal.Header closeButton>
          <Modal.Title>Настройки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs id="uncontrolled-tab-example" className="mb-3">
            {Object.keys(settingsStore.userSettings).length > 0 && (
              <Tab eventKey="pilots" title="Пилоты">
                <table border="0" width="100%" className={styles.chatLine}>
                  <thead>
                    <tr>
                      <td></td>
                      <td>CN</td>
                      <td>Имя</td>
                      <td>Метка</td>
                      <td>Трек</td>
                      {currentTask.id != 0 && <td>ААТ</td>}
                      {currentTask.id != 0 && <td>Проекция</td>}
                    </tr>
                  </thead>
                  <tbody>
                    {pilotItems}
                    {otherItems.length > 0 && (
                      <tr>
                        <td colSpan="7">
                          <hr />
                        </td>
                      </tr>
                    )}
                    {otherItems}
                  </tbody>
                </table>
              </Tab>
            )}
            {Object.keys(settingsStore.userSettings).length > 0 && (
              <Tab eventKey="table" title="Таблица">
                {!mediaQuery.matches && (
                  <div className={styles.chatLine}>
                    <label>
                      <input
                        type="checkbox"
                        checked={settingsStore.scoringTableSettings['userName']}
                        onChange={() => settingsStore.changeScoringTableSettings('userName')}
                      />{' '}
                      Имя пилота
                    </label>
                  </div>
                )}
                {currentTask.id != 0 && (
                  <div className={styles.chatLine}>
                    <label>
                      <input
                        type="checkbox"
                        checked={settingsStore.scoringTableSettings['distance']}
                        onChange={() => settingsStore.changeScoringTableSettings('distance')}
                      />{' '}
                      Дистанция
                    </label>
                  </div>
                )}
                {currentTask.id != 0 && (
                  <div className={styles.chatLine}>
                    <label>
                      <input
                        type="checkbox"
                        checked={settingsStore.scoringTableSettings['taskSpeed']}
                        onChange={() => settingsStore.changeScoringTableSettings('taskSpeed')}
                      />{' '}
                      Средняя скорость
                    </label>
                  </div>
                )}
                {!mediaQuery.matches && (
                  <div className={styles.chatLine}>
                    <label>
                      <input
                        type="checkbox"
                        checked={settingsStore.scoringTableSettings['speed']}
                        onChange={() => settingsStore.changeScoringTableSettings('speed')}
                      />{' '}
                      Скорость
                    </label>
                  </div>
                )}
                <div className={styles.chatLine}>
                  <label>
                    <input
                      type="checkbox"
                      checked={settingsStore.scoringTableSettings['alt']}
                      onChange={() => settingsStore.changeScoringTableSettings('alt')}
                    />{' '}
                    Высота
                  </label>
                </div>
                <div className={styles.chatLine}>
                  <label>
                    <input
                      type="checkbox"
                      checked={settingsStore.scoringTableSettings['vario']}
                      onChange={() => settingsStore.changeScoringTableSettings('vario')}
                    />{' '}
                    Скороподъемность
                  </label>
                </div>
                {currentTask.id != 0 && (
                  <div className={styles.chatLine}>
                    <label>
                      <input
                        type="checkbox"
                        checked={settingsStore.scoringTableSettings['time']}
                        onChange={() => settingsStore.changeScoringTableSettings('time')}
                      />{' '}
                      Время
                    </label>
                  </div>
                )}
              </Tab>
            )}
            <Tab eventKey="other" title="Общие">
              <table className={styles.chatLine}>
                <tbody>
                  <tr>
                    <td>Длина трека:</td>
                    <td>
                      <select
                        value={settingsStore.TailLength}
                        onChange={(e) => settingsStore.changeTailLength(e.target.value)}>
                        <option value={1}>1 минута</option>
                        <option value={5}>5 минут</option>
                        <option value={10}>10 минут</option>
                        <option value={15}>15 минут</option>
                        <option value={30}>30 минут</option>
                        <option value={60}>1 час</option>
                        <option value={120}>2 часа</option>
                        <option value={180}>3 часа</option>
                        <option value={240}>4 часа</option>
                        <option value={300}>5 часов</option>
                        <option value={600}>10 часов</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Активный трек:</td>
                    <td>
                      <select
                        value={settingsStore.SelectedTailLength}
                        onChange={(e) => settingsStore.changeSelectedTailLength(e.target.value)}>
                        <option value={5}>5 минут</option>
                        <option value={10}>10 минут</option>
                        <option value={15}>15 минут</option>
                        <option value={30}>30 минут</option>
                        <option value={60}>1 час</option>
                        <option value={120}>2 часа</option>
                        <option value={180}>3 часа</option>
                        <option value={240}>4 часа</option>
                        <option value={300}>5 часов</option>
                        <option value={360}>6 часов</option>
                        <option value={420}>7 часов</option>
                        <option value={480}>8 часов</option>
                        <option value={540}>9 часов</option>
                        <option value={600}>10 часов</option>
                        <option value={1440}>24 часа</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Скорость ветра:</td>
                    <td>
                      <select
                        value={settingsStore.windSpeed}
                        onChange={(e) => settingsStore.changeWindSpeed(e.target.value)}>
                        <option value={'ms'}>м/с</option>
                        <option value={'kph'}>км/ч</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="fzCheckbox">Запретные зоны:</label>
                    </td>
                    <td>
                      <input
                        id="fzCheckbox"
                        type="checkbox"
                        checked={settingsStore.showForbiddenZones}
                        onChange={(e) => settingsStore.changeFZ(e.target.checked)}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <label htmlFor="playersOnlyCheckbox">Только спортсмены:</label>
                    </td>
                    <td>
                      <input
                        id="playersOnlyCheckbox"
                        type="checkbox"
                        checked={settingsStore.showPlayersOnly}
                        onChange={(e) => settingsStore.changePlayersOnly(e.target.checked)}
                      />
                    </td>
                  </tr> */}
                  {trackingType !== 'condor' && (
                    <tr>
                      <td>
                        <label htmlFor="antennaCheckbox">Базовые станции:</label>
                      </td>
                      <td>
                        <input
                          id="antennaCheckbox"
                          type="checkbox"
                          checked={settingsStore.showAntenna}
                          onChange={(e) => settingsStore.changeShowAntenna(e.target.checked)}
                        />
                      </td>
                    </tr>
                  )}
                  {trackingType !== 'condor' && (
                    <tr>
                      <td>
                        <label htmlFor="weatherCheckbox">Погодные станции:</label>
                      </td>
                      <td>
                        <input
                          id="weatherCheckbox"
                          type="checkbox"
                          checked={settingsStore.showWeather}
                          onChange={(e) => settingsStore.changeShowWeather(e.target.checked)}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </HotKeys>
  );
}

export default observer(SettingsDialog);

import React from 'react';
import { Polyline, Marker, LayerGroup, Circle, Polygon, Popup } from 'react-leaflet';
import { observer } from 'mobx-react-lite';
import 'leaflet-semicircle';
import L from 'leaflet';

import SemiCircle from '../Controls/SemiCircle';
import settingsStore from '../Store/settingsStore';

function Task({ currentTask, trackingType }) {
  if (!currentTask || currentTask.serverID == 0) return;
  const windDir = Math.round(currentTask.windDir);
  const windSpeedTitle =
    settingsStore.windSpeed == 'ms' ? currentTask.windSpeed + ' м/с' : currentTask.windSpeed * 3.6 + ' км/ч';
  const ppmLabels = [];
  const ppmAreas = [];
  const forbiddenZones = [];

  currentTask.turnPoints.forEach((point, idx) => {
    let tpName = 'TP' + idx + ' ' + point.TPName;
    if (idx === currentTask.turnPoints.length - 1) tpName = 'Finish';
    if (idx === 0) tpName = 'Start';

    const icon = L.divIcon({
      className: 'turn-point-name', // Set class for CSS styling
      html: `
        <div style="
          display: flex;
          justify-content: center;">
          <b style="
            background-color: #ffffff;
            border: 1px solid;
            border-radius: 3px;
            font-weight: bolder;
            font-size: 12px;
            padding-left: 2px;
            padding-right: 2px;
            text-align: center;
            top: -20px;
            position: absolute;
            white-space: nowrap;
          ">${tpName}</b>
        </div>
        `
    });
    ppmLabels.push(
      <Marker key={idx} position={[point.TPPosX, point.TPPosY]} icon={icon}>
        <Popup>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Имя:</b>
                </td>
                <td>{point.TPName}</td>
              </tr>
              <tr>
                <td>
                  <b>Превышение:</b>
                </td>
                <td>{point.TPPosZ} м</td>
              </tr>
              <tr>
                <td>
                  <b>Радиус:</b>
                </td>
                <td>{point.TPRadius > 1000 ? point.TPRadius / 1000 + ' км' : point.TPRadius + ' м'}</td>
              </tr>
              {!!+point.legDistance && (
                <tr>
                  <td>
                    <b>Предыдущий отрезок:</b>
                  </td>
                  <td>{point.legDistance} км</td>
                </tr>
              )}
              {!!+point.nextTpDist && (
                <tr>
                  <td>
                    <b>Следующий отрезок:</b>
                  </td>
                  <td>{point.nextTpDist} км</td>
                </tr>
              )}
            </tbody>
          </table>
        </Popup>
      </Marker>
    );

    if (point.TPAngle === 360) {
      ppmAreas.push(
        <Circle
          key={idx}
          center={[point.TPPosX, point.TPPosY]}
          radius={point.TPRadius}
          pathOptions={{ color: 'red', weight: 2, fillColor: 'green', fillOpacity: 0.3 }}
          interactive={false}
        />
      );
    } else {
      ppmAreas.push(
        <SemiCircle
          key={idx}
          center={[point.TPPosX, point.TPPosY]}
          color="red"
          fillColor="green"
          fillOpacity="0.3"
          direction={point.TPOuterAngle + 180}
          angle={point.TPAngle}
          radius={point.TPRadius}
          interactive={false}
        />
      );
    }
    return;
  });

  const taskLines = currentTask.turnPoints.map((point) => {
    return [point.TPPosX, point.TPPosY];
  });

  settingsStore.showForbiddenZones &&
    currentTask.forbiddenZones.forEach((zone, idx) => {
      if (zone.active) {
        let label = '';
        if (zone.PZBase !== undefined) label = zone.PZBase + '-' + zone.PZTop;
        else if (zone.AN !== undefined) label += zone.AN;
        else if (zone.AL !== undefined) label += zone.AL;
        else if (zone.AH !== undefined) label += zone.AH;

        const zoneLabel = new L.divIcon({
          html: `
          <div style="
            display: flex;
            justify-content: center;">
            <b style="
              position: absolute;
              border: 0px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 12px;
              padding-left: 0px;
              padding-right: 0px;
              top: 0px;
              text-align: center;
              background-color: yellow;
              padding-left: 2px;
              padding-right: 2px;
            ">${label}</b>
          </div>
        `,
          iconSize: [10, 10],
          className: 'glider-icon-cn'
        });
        const positions = zone.points.map((point) => {
          return [point.lat, point.lon];
        });
        let fzCenterLat = 0;
        let fzCenterLon = 0;
        zone.points.forEach((point) => {
          fzCenterLat += point.lat;
          fzCenterLon += point.lon;
        });
        fzCenterLat /= zone.points.length;
        fzCenterLon /= zone.points.length;
        forbiddenZones.push(<Polygon key={'fzp' + idx} color="yellow" positions={positions} interactive={false} />);
        forbiddenZones.push(
          <Marker key={'fzm' + idx} position={[fzCenterLat, fzCenterLon]} icon={zoneLabel}>
            <Popup>
              <table>
                <tbody>
                  {zone.AC !== undefined && (
                    <tr>
                      <td>
                        <b>AC:</b>
                      </td>
                      <td>{zone.AC}</td>
                    </tr>
                  )}
                  {zone.AN !== undefined && (
                    <tr>
                      <td>
                        <b>AN:</b>
                      </td>
                      <td>{zone.AN}</td>
                    </tr>
                  )}
                  {zone.AL !== undefined && (
                    <tr>
                      <td>
                        <b>AL:</b>
                      </td>
                      <td>{zone.AL}</td>
                    </tr>
                  )}
                  {zone.AH !== undefined && (
                    <tr>
                      <td>
                        <b>AH:</b>
                      </td>
                      <td>{zone.AH}</td>
                    </tr>
                  )}
                  {zone.PZBase !== undefined && (
                    <tr>
                      <td>
                        <b>AL:</b>
                      </td>
                      <td>{zone.PZBase}</td>
                    </tr>
                  )}
                  {zone.PZTop !== undefined && (
                    <tr>
                      <td>
                        <b>AH:</b>
                      </td>
                      <td>{zone.PZTop}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Popup>
          </Marker>
        );
      }
    });

  return (
    <LayerGroup>
      {ppmLabels}
      {ppmAreas}
      {forbiddenZones}
      <Polyline pathOptions={{ color: 'blue', weight: 2 }} positions={taskLines} interactive={false} />
      <div
        style={{
          bottom: `80px`,
          right: `15px`,
          position: `fixed`,
          zIndex: `500`,
          opacity: `0.8`,
          transform: `rotate(${windDir}deg)`
        }}>
        <img title={windSpeedTitle} alt={windDir} src="/strelka2.png" width="40" height="60" />
      </div>
    </LayerGroup>
  );
}

export default observer(Task);

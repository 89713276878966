import { useEffect } from 'react';
import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';

const SemiCircle = (props) => {
  const context = useLeafletContext();
  const { center, radius, color, fillColor, fillOpacity, direction, angle, interactive } = props;

  useEffect(() => {
    // const ellipse = new L.Ellipse(center, radii, tilt, options)

    const semiCircle = L.semiCircle(center, {
      color,
      radius,
      fillColor,
      fillOpacity,
      interactive
    })
      .setDirection(direction + 180, angle)
      .setStyle({ color, weight: 2 });

    const container = context.layerContainer || context.map;

    container.addLayer(semiCircle);

    return () => {
      container.removeLayer(semiCircle);
    };
  });

  return null;
};

export default SemiCircle;

import 'leaflet-rotatedmarker';
import React, { forwardRef, useEffect, useRef } from 'react';
import { Marker as MarkerLeaflet } from 'react-leaflet';

const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
  const markerRef = useRef();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin('center');
    }
  }, [rotationAngle, rotationOrigin]);

  return (
    <MarkerLeaflet
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}>
      {children}
    </MarkerLeaflet>
  );
});

RotatedMarker.displayName = 'RotatedMarker';

export default RotatedMarker;

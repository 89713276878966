import React, { useState } from 'react';
import L from 'leaflet';
import { BiUpArrow, BiDownArrow } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { Marker } from 'react-leaflet';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import CanvasJSReact from '@canvasjs/react-charts';

import chartSettingsStore from '../Store/chartSettingsStore';
import settingsStore from '../Store/settingsStore';

import styles from '../styles/Home.module.css';

function AltChart() {
  const resizeChartPlus = () => {
    if (chartHeight < 600) resizeChart(20);
  };
  const resizeChartMinus = () => {
    if (chartHeight >= 120) resizeChart(-20);
  };

  const resizeChart = (v) => {
    setChartHeight(Number(localStorage.getItem('chartHeight')) + v);
    localStorage.setItem('chartHeight', chartHeight + v);
  };
  const [chartHeight, setChartHeight] = useState(Number(localStorage.getItem('chartHeight')));

  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [shadowPosition, setShadowPosition] = useState([0, 0]);

  const options = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    toolTip: {
      shared: true,
      updated: function (e) {
        if (shadowPosition[0] != e.entries[0].dataPoint.lat || shadowPosition[1] != e.entries[0].dataPoint.lon)
          setShadowPosition([e.entries[0].dataPoint.lat, e.entries[0].dataPoint.lon]);
        chartSettingsStore.setShowShadowMarker(true);
      },
      hidden: function () {
        chartSettingsStore.setShowShadowMarker(false);
      }
    },
    axisX: {
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      },
      valueFormatString: 'HH:mm:ss',
      stripLines: chartSettingsStore.chartTurnPoints
    },
    axisY: {
      suffix: 'м',
      minimum: 0,
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    data: [
      {
        type: 'spline',
        connectNullData: true,
        nullDataLineDashType: 'dot',
        toolTipContent: 'MSL: {y} м<br>AGL: {agl} м',
        name: 'alt',
        color: 'red',
        dataPoints: chartSettingsStore.chartPoints
      },
      {
        type: 'splineArea',
        connectNullData: false,
        toolTipContent: 'E: {y} м',
        name: 'ground',
        color: 'rgb(193, 109, 37)',
        dataPoints: chartSettingsStore.chartGroundPoints
      }
    ]
  };
  if (
    chartSettingsStore.activeChartPilot == '' ||
    settingsStore.userSettings[chartSettingsStore.activeChartPilot] == undefined
  )
    return;
  let pilotName = settingsStore.userSettings[chartSettingsStore.activeChartPilot].userName;
  const gliderCN = new L.divIcon({
    html: `
          <div style="
            display: flex;
            justify-content: center;">
            <b style="
              background-color: #fffffff0;
              position: absolute;
              border: 1px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 14px;
              padding-left: 2px;
              padding-right: 2px;
              top: -25px;
              text-align: center;
            ">${settingsStore.userSettings[chartSettingsStore.activeChartPilot].cn}</b>
          </div>
        `,
    iconSize: [10, 10],
    className: 'glider-icon-cn'
  });

  return (
    <div className={styles[`altChart`]}>
      <table width="100%">
        <tbody>
          <tr className={styles.altChartTitleBar}>
            <td style={{ paddingLeft: '10px' }}>
              {pilotName} ({settingsStore.userSettings[chartSettingsStore.activeChartPilot].cn})
            </td>
            <td align="right" style={{ paddingRight: '10px' }}>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  resizeChartPlus();
                }}>
                <BiUpArrow style={{ marginRight: '1px' }} />
              </Button>{' '}
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  resizeChartMinus();
                }}>
                <BiDownArrow style={{ marginRight: '1px' }} />
              </Button>{' '}
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  chartSettingsStore.setActiveChartGlider('');
                }}>
                <GrClose style={{ marginRight: '1px' }} />
              </Button>
            </td>
          </tr>
          <tr colSpan="2">
            <td height={chartHeight}>
              <CanvasJSChart options={options} containerProps={{ width: '100%', height: '100%' }} />
              {chartSettingsStore.showShadowMarker && (
                <Marker id="shadowMarker" key="ss" position={shadowPosition} icon={gliderCN}></Marker>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default observer(AltChart);

import React from 'react';
import { Marker, Tooltip, Popup } from 'react-leaflet';
import L from 'leaflet';

import settingsStore from '../Store/settingsStore';
import { observer } from 'mobx-react-lite';

function AprsReceivers(props) {
  const { currentTask } = props;
  const receivers = [];
  if (!currentTask || settingsStore.showAntenna == false) {
    return;
  }
  const antennaIcon = new L.divIcon({
    html: `
      <div>
      <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 512.003 512.003" xml:space="preserve">
   <circle style="fill:#B0DACC;" cx="256.003" cy="99.294" r="53.05"/>
   <g>
     <path style="fill:#211E48;" d="M256.001,166.175c-17.865,0-34.66-6.957-47.292-19.59c-12.631-12.633-19.589-29.427-19.589-47.292
       c0-17.863,6.957-34.659,19.589-47.292c12.633-12.631,29.427-19.589,47.292-19.589s34.659,6.957,47.292,19.589
       c12.631,12.633,19.589,29.429,19.589,47.292c0,17.865-6.957,34.659-19.589,47.292C290.661,159.217,273.865,166.175,256.001,166.175
       z M256.001,60.064c-10.479,0-20.33,4.08-27.739,11.491c-7.409,7.409-11.491,17.26-11.491,27.739s4.08,20.33,11.489,27.739
       c7.409,7.409,17.26,11.492,27.739,11.492s20.33-4.081,27.738-11.492c7.409-7.408,11.491-17.259,11.491-27.739
       c0-10.477-4.08-20.328-11.491-27.739C276.33,64.144,266.479,60.064,256.001,60.064z"/>
     <path style="fill:#211E48;" d="M392.044,198.584c-3.938,0-7.848-1.673-10.582-4.921c-4.919-5.841-4.171-14.563,1.67-19.482
       c2.276-1.916,4.462-3.92,6.494-5.953c19.28-19.279,29.899-44.962,29.899-72.313s-10.618-53.032-29.899-72.313
       c-5.399-5.399-5.399-14.154,0-19.554c5.399-5.398,14.154-5.398,19.554,0c50.654,50.656,50.654,133.077,0,183.732
       c-2.59,2.588-5.36,5.129-8.236,7.552C398.35,197.518,395.188,198.584,392.044,198.584z"/>
     <path style="fill:#211E48;" d="M344.889,176.241c-4.088,0-8.135-1.804-10.863-5.262c-4.73-5.995-3.705-14.689,2.29-19.419
       c2.211-1.745,4.318-3.614,6.263-5.561c13.338-13.338,20.684-31.104,20.684-50.028s-7.346-36.689-20.681-50.025
       c-5.399-5.399-5.4-14.154,0-19.554c5.399-5.398,14.154-5.398,19.553,0c18.56,18.56,28.782,43.27,28.782,69.578
       s-10.222,51.019-28.78,69.579c-2.707,2.707-5.631,5.305-8.69,7.718C350.908,175.271,347.887,176.241,344.889,176.241z"/>
     <path style="fill:#211E48;" d="M119.958,198.584c-3.144,0-6.306-1.066-8.9-3.25c-2.876-2.422-5.647-4.964-8.236-7.552
       c-50.654-50.656-50.654-133.077,0-183.732c5.399-5.398,14.154-5.398,19.554,0c5.399,5.399,5.399,14.154,0,19.554
       c-19.282,19.28-29.899,44.962-29.899,72.314s10.618,53.032,29.899,72.313c2.034,2.034,4.218,4.037,6.494,5.953
       c5.841,4.919,6.588,13.641,1.67,19.482C127.806,196.911,123.895,198.584,119.958,198.584z"/>
     <path style="fill:#211E48;" d="M167.113,176.241c-2.999,0-6.018-0.971-8.556-2.971c-3.058-2.413-5.983-5.011-8.691-7.719
       c-18.557-18.559-28.779-43.27-28.779-69.578s10.222-51.018,28.782-69.578c5.399-5.398,14.154-5.396,19.553,0
       c5.399,5.399,5.399,14.154,0,19.554c-13.337,13.337-20.681,31.102-20.681,50.025s7.346,36.689,20.682,50.027
       c1.947,1.948,4.054,3.819,6.265,5.562c5.995,4.73,7.019,13.424,2.29,19.419C175.249,174.437,171.2,176.241,167.113,176.241z"/>
     <path style="fill:#211E48;" d="M333.154,512.003h-17.701c-7.636,0-13.826-6.191-13.826-13.826c0-7.635,6.19-13.826,13.826-13.826
       h17.701c4.051,0,6.428-2.319,7.484-3.7c1.056-1.383,2.67-4.285,1.607-8.195l-83.317-306.272c-1.949,0.084-3.902,0.084-5.851,0
       L169.76,472.456c-1.063,3.91,0.549,6.812,1.607,8.195c1.056,1.381,3.433,3.7,7.484,3.7h81.3c7.636,0,13.826,6.191,13.826,13.826
       c0,7.635-6.19,13.826-13.826,13.826h-81.3c-11.646,0-22.382-5.306-29.454-14.56c-7.073-9.252-9.375-21.004-6.318-32.244
       l86.515-318.027c1.98-7.277,9.424-11.618,16.735-9.774c6.33,1.604,13.021,1.604,19.351,0c7.308-1.844,14.755,2.5,16.735,9.774
       l86.515,318.027c3.057,11.239,0.754,22.991-6.318,32.244C355.535,506.696,344.799,512.003,333.154,512.003z"/>
     <path style="fill:#211E48;" d="M260.838,409.819H176.23c-7.636,0-13.826-6.191-13.826-13.826c0-7.635,6.19-13.826,13.826-13.826
       h84.608c7.636,0,13.826,6.191,13.826,13.826C274.664,403.628,268.474,409.819,260.838,409.819z"/>
   </g>
   </svg>
   </div>
    `,
    iconSize: [50, 50],
    className: 'glider-icon-red'
  });

  currentTask.receivers.forEach((receiver) => {
    receivers.push(
      <Marker
        key={receiver.aprsID}
        position={[receiver.lat, receiver.lon]}
        icon={antennaIcon}
        eventHandlers={{
          click: () => {
            //console.log(receiver.aprsID);
          },
          contextmenu: () => {
            console.log(receiver.aprsID);
          }
        }}>
        <Tooltip>{receiver.aprsID}</Tooltip>
        <Popup>
          <b>
            {receiver.aprsID} ({receiver.trackerTime} UTC)
          </b>
          <hr />
          {receiver.data}
        </Popup>
      </Marker>
    );
  });

  return <>{receivers}</>;
}

export default observer(AprsReceivers);

import React from 'react';
import ReactDOM from 'react-dom/client';
import { useRouteError, createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import './index.css';
import './styles/globals.css';

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>ERROR: {JSON.stringify(error)}</div>;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App trackingType="live" />,
    errorElement: <ErrorBoundary />
  },
  {
    path: '/live/',
    element: <App trackingType="live" />,
    errorElement: <ErrorBoundary />
  },
  {
    path: '/livetracking/',
    element: <App trackingType="condor" />,
    errorElement: <ErrorBoundary />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

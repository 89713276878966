import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { BiArrowFromTop, BiArrowFromBottom, BiNoSignal } from 'react-icons/bi';

import {
  FaFlagCheckered,
  FaPlane,
  FaPlaneDeparture,
  FaPlaneArrival,
  FaUser,
  FaAnchor,
  FaHome,
  FaRegPaperPlane
} from 'react-icons/fa';

import { SiHappycow, SiApachedruid } from 'react-icons/si';
import { GiCarWheel, GiWaterDrop } from 'react-icons/gi';
import { TbPlaneOff, TbPropeller, TbPropellerOff } from 'react-icons/tb';
import { MdOutlineParagliding } from 'react-icons/md';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from '../styles/Home.module.css';
import settingsStore from '../Store/settingsStore';
import chartSettingsStore from '../Store/chartSettingsStore';

function status2Text(status) {
  if (status === 'Finished') return 'Финишировал';
  if (status === 'OutLanded') return 'Посадка вне аэродрома';
  if (status === 'Crashed') return 'Авария';
  if (status === 'Landed') return 'Посадка на аэродром';
  if (status === 'Racing') return 'Гонка';
  if (status === 'Warmup') return 'Взлет';
  if (status === 'Hangar') return 'На земле';
  if (status === 'Freefly') return 'Фрифлай';
  if (status === 'FreeflyParagliding') return 'Фрифлай (Параплан)';
  if (status === 'Home') return 'Вернулся с площадки';
  return '???';
}

function ScoringTable({ points, currentTask }) {
  const [isOpen, setOpen] = useState(true);
  const toggleShow = () => {
    setShow((s) => !s);
  };
  const [show, setShow] = useState(false);
  const [infoData, setInfoData] = useState({});
  const map = useMap();
  if (currentTask == null) return;
  const maxTableHeight = window.innerHeight - 180;
  //console.log(maxTableHeight);
  return (
    <>
      <div
        className={styles.taskInfo}
        onMouseOver={() => map.scrollWheelZoom.disable()}
        onMouseLeave={() => map.scrollWheelZoom.enable()}
        onTouchStart={() => map.dragging.disable()}
        onTouchEnd={() => map.dragging.enable()}>
        {points?.length > 0 && (
          <div style={{ display: isOpen ? 'block' : 'none' }}>
            <div key="head" style={{ display: 'flex', cursor: 'default' }}>
              <div className={styles.pilotInfo}>
                <div
                  className={styles.pilotColorMark}
                  style={{ backgroundColor: 'black' }}
                  onClick={() => settingsStore.showAllTails()}></div>
                <div className={styles.pilotCN} title="Соревновательный номер">
                  CN
                </div>
                <div className={styles.pilotStatusWarmup} title="Статус"></div>
                {settingsStore.scoringTableSettings['userName'] && <div className={styles.pilotName}>Имя</div>}
              </div>
              <div className={styles.pilotFlightHud}>
                {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['distance'] && (
                  <div className={styles.pilotDistance} title="Пройденная дистанция">
                    D <small>км</small>
                  </div>
                )}
                {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['taskSpeed'] && (
                  <div className={styles.pilotAvgSpeed} title="Скорость по заданию">
                    V* <small>км/ч</small>
                  </div>
                )}
                {settingsStore.scoringTableSettings['speed'] && (
                  <div className={styles.pilotCurrentSpeed} title="Скорость относительно земли">
                    V <small>км/ч</small>
                  </div>
                )}
                {settingsStore.scoringTableSettings['alt'] && (
                  <div className={styles.pilotAlt} title="Высота (MSL)">
                    H <small>м</small>
                  </div>
                )}
                {settingsStore.scoringTableSettings['vario'] && (
                  <div className={styles.pilotVarioTitle} title="Скороподъемность">
                    Vs <small>м/с</small>
                  </div>
                )}
                {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['time'] && (
                  <div className={styles.pilotTaskTime} title="Время задания">
                    Время
                  </div>
                )}
              </div>
            </div>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={maxTableHeight}>
              <div key="body" style={{ position: 'relative' }}>
                {!!points?.length &&
                  points.map((p) => {
                    if (p.needScoring == 0 || !settingsStore.userSettings[p.id]) return;
                    const iconColor = settingsStore.userSettings[p.id].userColor;
                    let vario = Math.round(p.vario * 10) / 10;
                    let varioClass = 'pilotVarioZero';
                    if (vario > 0.3) {
                      varioClass = 'pilotVarioPositive';
                      vario = `+${vario}`;
                    } else if (vario < -0.3) {
                      varioClass = 'pilotVarioNegative';
                    }
                    if (!`${vario}`.includes('.')) {
                      vario = `${vario}.0`;
                    }
                    return (
                      <div key={p.id} style={{ display: 'flex', cursor: 'default' }}>
                        <div className={styles.pilotInfo}>
                          <div
                            className={styles.pilotColorMark}
                            style={{ backgroundColor: iconColor }}
                            onClick={() => {
                              settingsStore.userSettings[p.id].showTail = !settingsStore.userSettings[p.id].showTail;
                            }}></div>
                          <div
                            className={styles.pilotCN}
                            onClick={() => {
                              chartSettingsStore.setActiveChartGlider(p.id);
                            }}
                            title={p.userName}>
                            {p.cn}
                          </div>
                          <div
                            className={styles[`pilotStatus${p.status}`]}
                            onClick={(e) => {
                              //console.log(e.detail);
                              toggleShow();
                              setInfoData(p);
                            }}>
                            {p.status === 'Finished' && <FaFlagCheckered title={status2Text(p.status)} />}
                            {p.status === 'OutLanded' && <SiHappycow title={status2Text(p.status)} />}
                            {p.status === 'Crashed' && <TbPlaneOff title={status2Text(p.status)} />}
                            {p.status === 'Landed' && <FaPlaneArrival title={status2Text(p.status)} />}
                            {p.status === 'Racing' && <FaPlane title={status2Text(p.status)} />}
                            {p.status === 'Warmup' && <FaPlaneDeparture title={status2Text(p.status)} />}
                            {p.status === 'Hangar' && <FaUser title={status2Text(p.status)} />}
                            {p.status === 'Home' && <FaHome title={status2Text(p.status)} />}
                            {p.status === 'Freefly' && <FaRegPaperPlane title={status2Text(p.status)} />}
                            {p.status === 'FreeflyParagliding' && (
                              <MdOutlineParagliding title={status2Text(p.status)} />
                            )}
                          </div>

                          {settingsStore.scoringTableSettings['userName'] && (
                            <div
                              className={styles.pilotName}
                              onClick={() => {
                                map.panTo([p.lat, p.lon]);
                              }}>
                              {p.userName}
                            </div>
                          )}
                        </div>

                        <div className={styles.pilotFlightHud}>
                          {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['distance'] && (
                            <div className={styles.pilotDistance} title="Пройденная дистанция">
                              {p.taskDistance}{' '}
                            </div>
                          )}
                          {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['taskSpeed'] && (
                            <div className={styles.pilotAvgSpeed} title="Скорость по заданию">
                              {p.taskSpeed}
                            </div>
                          )}
                          {settingsStore.scoringTableSettings['speed'] && (
                            <div className={styles.pilotCurrentSpeed} title="Скорость относительно земли">
                              {Math.round(p.speed)}
                            </div>
                          )}
                          {settingsStore.scoringTableSettings['alt'] && (
                            <div className={styles.pilotAlt} title="Высота (MSL)">
                              {p.alt}
                            </div>
                          )}
                          {settingsStore.scoringTableSettings['vario'] && (
                            <div className={styles[varioClass]} title="Скороподъемность">
                              {vario}
                            </div>
                          )}
                          {currentTask.raceType !== 'FF' && settingsStore.scoringTableSettings['time'] && (
                            <div className={styles.pilotTaskTime} title="Время задания">
                              {p.taskTime}
                            </div>
                          )}
                        </div>
                        {p.signalStatus === 0 && (
                          <div className={styles.pilotNoSignal} title="Отсутствие сигнала более 1 минуты">
                            <BiNoSignal />
                          </div>
                        )}
                        {p.penalty > 0 && (
                          <div className={styles.pilotPenalty} title="Штраф">
                            {p.penalty}
                          </div>
                        )}
                        {!!+p.gear && (
                          <div className={styles.pilotGear} title="Шасси выпущены">
                            <GiCarWheel style={{ marginRight: '1px' }} />
                          </div>
                        )}
                        {!!+p.water && (
                          <div className={styles.pilotGear} title="Слив водобалласта">
                            <GiWaterDrop style={{ marginRight: '1px' }} />
                          </div>
                        )}
                        {!!+p.brakes && (
                          <div className={styles.pilotGear} title="Выпущенные интерцепторы">
                            <FaAnchor style={{ marginRight: '1px' }} />
                          </div>
                        )}
                        {!!+p.smoke && (
                          <div className={styles.pilotGear} title="Дымы">
                            <SiApachedruid style={{ marginRight: '1px' }} />
                          </div>
                        )}
                        {!!+p.engine && (
                          <div className={styles.pilotProp} title="Работа двигателя">
                            {p.engine == 1 && (
                              <TbPropellerOff style={{ marginRight: '1px' }} title="Двигатель выпущен" />
                            )}
                            {p.engine == 2 && <TbPropeller style={{ marginRight: '1px' }} title="Двигатель работает" />}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Scrollbars>
          </div>
        )}

        {!!points?.length && (
          <div
            className={styles.collapseBtn}
            onClick={() => {
              setOpen(!isOpen);
              map.scrollWheelZoom.enable();
            }}>
            {isOpen ? <BiArrowFromBottom /> : <BiArrowFromTop />}
          </div>
        )}
      </div>
      {!!points?.length && (
        <Modal show={show} onHide={() => setShow(false)} scrollable={true} size="lx">
          <Modal.Header closeButton>
            <Modal.Title>
              {infoData.userName} ({infoData.cn})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className={styles.chatLine}>
              <tbody valign="top">
                <tr>
                  <td>
                    <b>Статус:</b>
                  </td>
                  <td>{status2Text(infoData.status)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Координаты:</b>
                  </td>
                  <td>
                    {infoData.lat} {infoData.lon}
                  </td>
                </tr>
                {!!infoData?.aprsID && (
                  <tr>
                    <td>
                      <b>Трекер:</b>
                    </td>
                    <td>{infoData.aprsID}</td>
                  </tr>
                )}
                {!!infoData?.receiverName && (
                  <tr>
                    <td>
                      <b>Приемник:</b>
                    </td>
                    <td>
                      {infoData.receiverName} {infoData.receiverDist > -1 && <>({infoData.receiverDist} км)</>}
                    </td>
                  </tr>
                )}
                {!!infoData?.distToFinish && (
                  <tr>
                    <td>
                      <b>Расстояние до аэродрома:</b>
                    </td>
                    <td>{infoData.distToFinish} км</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Планер:</b>
                  </td>
                  <td>{infoData.plane}</td>
                </tr>
                <tr>
                  <td>
                    <b>Высота MSL:</b>
                  </td>
                  <td>{infoData.alt} м</td>
                </tr>
                <tr>
                  <td>
                    <b>Высота AGL:</b>
                  </td>
                  <td>{infoData.alt - infoData.elevation} м</td>
                </tr>
                <tr>
                  <td>
                    <b>Превышение:</b>
                  </td>
                  <td>{infoData.elevation} м</td>
                </tr>
                {currentTask.raceType !== 'FF' && (
                  <tr>
                    <td>
                      <b>Время задания:</b>
                    </td>
                    <td>{infoData.taskTime}</td>
                  </tr>
                )}
                {currentTask.raceType !== 'FF' && (
                  <tr>
                    <td>
                      <b>Средняя скорость:</b>
                    </td>
                    <td>{infoData.taskSpeed} км/ч</td>
                  </tr>
                )}
                {currentTask.raceType !== 'FF' && (
                  <tr>
                    <td>
                      <b>Пройденная дистанция:</b>
                    </td>
                    <td>{infoData.taskDistance} км</td>
                  </tr>
                )}
                {!!infoData?.noSignalTime && (
                  <tr>
                    <td>
                      <b>Отсутствие сигнала:</b>
                    </td>
                    <td> {infoData.noSignalTime} </td>
                  </tr>
                )}
                {!!infoData?.trackerComment && (
                  <tr>
                    <td>
                      <b>Дополнительно:</b>
                    </td>
                    <td> {infoData.trackerComment} </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default observer(ScoringTable);

import { makeAutoObservable } from 'mobx';
import trackingStore from './trackingStore';
import { numberWithCommas } from '../helpers';

class chartSettingsStore {
  activeChartPilot = '';
  chartPoints = [];
  chartGroundPoints = [];
  chartTurnPoints = [];
  showShadowMarker = false;
  reloadChartPilot = false;
  chartHeight = 120;

  constructor() {
    makeAutoObservable(this);
    if (localStorage.getItem('chartHeight') === null) localStorage.setItem('chartHeight', this.chartHeight);
    this.changeChartHeight(localStorage.getItem('chartHeight'));
  }

  convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  setShowShadowMarker(value) {
    this.showShadowMarker = value;
  }
  setActiveChartGlider(pilotId) {
    console.log('setActiveChartGlider:' + pilotId);
    if (pilotId == this.activeChartPilot) return;
    this.activeChartPilot = pilotId;
    this.showShadowMarker = false;
    if (this.activeChartPilot != '') {
      this.reloadChartPilot = true;
      this.updateChartPointsForActiveGlider(pilotId);
    }
  }
  changeChartHeight(value) {
    this.chartHeight = value;
    localStorage.setItem('chartHeight', this.chartHeight);
  }
  changeReloadChartPilot(value) {
    this.reloadChartPilot = value;
  }
  updateChartPointsForActiveGlider(pilotId) {
    this.chartPoints.length = 0;
    this.chartGroundPoints.length = 0;
    this.chartTurnPoints.length = 0;

    var lastTime = 0;
    trackingStore.tails[pilotId].forEach((element) => {
      var thisTime = new Date(element.time).getTime();
      // Если данных не было более 2 минут, ставим NULL точку, которая потом даст разрыв или линию из точек на графике
      if (lastTime > 0 && (thisTime - lastTime) / 60 / 1000 > 2) {
        let nullPointTime = Date(element.time) - 10000;
        this.chartPoints.push({
          x: this.convertUTCDateToLocalDate(new Date(nullPointTime)),
          y: null,
          agl: null,
          lat: element.lat,
          lon: element.lon
        });
        this.chartGroundPoints.push({
          x: this.convertUTCDateToLocalDate(new Date(nullPointTime)),
          y: null
        });
      }
      lastTime = thisTime;
      this.chartPoints.push({
        x: this.convertUTCDateToLocalDate(new Date(element.time)),
        y: element.alt,
        agl: numberWithCommas(element.alt - element.elevation),
        lat: element.lat,
        lon: element.lon
      });
      this.chartGroundPoints.push({
        x: this.convertUTCDateToLocalDate(new Date(element.time)),
        y: element.elevation
      });
    });
    this.chartTurnPoints = [];
    if (trackingStore.tails[pilotId].length == 0) return;
    var lastPoint = trackingStore.tails[pilotId][trackingStore.tails[pilotId].length - 1];
    //console.log(lastPoint);
    lastPoint.aatPoints.forEach((point) => {
      if (point.time != lastPoint.time) {
        this.chartTurnPoints.push({
          value: this.convertUTCDateToLocalDate(new Date(point.time)),
          color: 'blue',
          label: point.zone,
          labelFontColor: 'blue',
          labelFontSize: 16,
          labelFontStyle: 'oblique', // “normal”,”italic”,”oblique”
          thickness: 2,
          lineDashType: 'dash'
        });
      }
    });
    // console.log(this.chartTurnPoints);
  }
}

export default new chartSettingsStore();
